import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { LocaleContext } from '../components/layout'
import tw, { styled } from 'twin.macro'
import SEO from '../components/SEO/SEO'
import { withPreview } from 'gatsby-source-prismic'
import Logo from '../images/logo-square.png'
// styles
const PrivacyWrapper = styled.div`
  h2 {
    margin-bottom: 8px;
  }
  h3 {
    margin-bottom: 8px;
  }
  p {
    margin-bottom: 32px;
  }
  ul {
    li {
      list-style-type: disc;
    }
    margin-bottom: 32px;
  }
`
// markup
const PrivacyPage = ({ data: { prismicPrivacy }, pageContext: { locale } }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  return (
    <main>
      <SEO
        title={prismicPrivacy.data.meta_title}
        desc={prismicPrivacy.data.meta_description}
        locale={locale}
      />
      <PrivacyWrapper tw="container py-32">
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: prismicPrivacy.data.content.html,
            }}
            tw="text-lg text-ocean"></div>
        </div>
      </PrivacyWrapper>
    </main>
  )
}

export default withPreview(PrivacyPage)

export const pageQuery = graphql`
  query PrivacyQuery($locale: String!) {
    prismicPrivacy(lang: { eq: $locale }) {
      data {
        meta_description
        meta_title
        content {
          html
        }
      }
      uid
    }
  }
`
